import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import { context } from "../../provider"
import { useReturnMediaSize } from "../utils/helpers"

import { IoIosMenu } from "react-icons/io"

import "./Navbar.scss"
import { motion } from "framer-motion"
import PageTransitionLink from "../components/PageTransitionLink"

import { useLocation } from "@reach/router"

const Navbar = () => {
  const { navState, setNavState } = useContext(context)
  const mediaSize = useReturnMediaSize()
  const isDev = useIsDev()

  const location = useLocation()

  useEffect(() => {
    console.log(location)
  }, [location.pathname])

  const isMobile = mediaSize === "mobile" || mediaSize === "smtablet"
  return (
    <div className="navbar">
      <h4 className="navbar__maxvr-logo font-extrabold tracking-tighter">
        <PageTransitionLink to="/">
          <span className="font-light">MAX</span>VR
        </PageTransitionLink>
      </h4>

      {isDev && <div className="ml-8">{mediaSize}</div>}

      <IoIosMenu
        className="navbar__menu-icon"
        onClick={() => setNavState({ ...navState, isOpen: !navState.isOpen })}
      />

      <motion.div
        className="navbar__page-links"
        animate={{ height: isMobile && !navState.isOpen ? 0 : "auto" }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        <PageTransitionLink
          to="/architectural-rendering/"
          activeClassName="active"
        >
          Architectural Rendering
        </PageTransitionLink>
        <PageTransitionLink to="/virtual-reality/" activeClassName="active">
          Virtual Reality
        </PageTransitionLink>
        <PageTransitionLink to="/portfolio/" activeClassName="active">
          Portfolio
        </PageTransitionLink>
        <PageTransitionLink to="/pricing/" activeClassName="active">
          Pricing
        </PageTransitionLink>
        <PageTransitionLink to="/contact/" activeClassName="active">
          Contact
        </PageTransitionLink>
      </motion.div>
    </div>
  )
}

export default Navbar

const useIsDev = () => {
  const [isDev, setIsDev] = useState(false)

  useEffect(() => {
    if (window.location.href.includes("localhost")) {
      setIsDev(true)
    }
  }, [])
  return isDev
}
