import { AnimatePresence } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"

import Navbar from "./Navbar"
import Footer from "./Footer"
import PageTransitionPanels from "./PageTransitionPanels"
import gsap from "gsap"
import { select } from "../utils/helpers"

const Layout = ({ children }) => {
  useEffect(() => {
    select(".layout-container").style.opacity = 1
  }, [])

  return (
    <>
      <div className="layout-container" style={{ opacity: 0 }}>
        <Navbar />
        <div className="min-h-screen flex flex-col justify-between">
          <main>{children}</main>
          <Footer />
        </div>
      </div>

      <PageTransitionPanels />
    </>
  )
}

export default Layout
