import React, { useState, useEffect } from "react"

export const context = React.createContext()

const Provider = ({ children }) => {
  const [navState, setNavState] = useState({
    isOpen: false,
    isAnimating: false,
  })
  const [transitionPageTo, setTransitionPageTo] = useState(() => {})

  const providerContext = {
    navState,
    setNavState,
    transitionPageTo,
    setTransitionPageTo,
  }

  return <context.Provider value={providerContext}>{children}</context.Provider>
}

export default ({ element }) => <Provider>{element}</Provider>
