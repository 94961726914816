import { motion, useAnimation } from "framer-motion"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { context } from "../../provider"

import "./PageTransitionPanels.scss"

const CONTAINER_VARIANTS = {
  covering: { opacity: 1 },
  exitTop: {
    opacity: 1,
  },
  enterBottom: {
    opacity: 1,
  },
}

const PANEL_VARIANTS = {
  covering: i => {
    return {
      y: "0%",
      transition: {
        delay: i * 0.15,
        ease: "easeInOut",
        duration: 0.6,
      },
    }
  },
  exitTop: i => {
    return {
      y: "-100%",
      transition: {
        delay: i * 0.15,
        ease: "easeInOut",
        duration: 0.6,
      },
    }
  },
  enterBottom: i => {
    return {
      y: "100%",
      transition: {
        duration: 0,
      },
    }
  },
}

const PageTransitionPanels = () => {
  const { setTransitionPageTo, navState, setNavState } = useContext(context)
  const controls = useAnimation("exitTop")

  useEffect(() => {
    const pageTransition = async destination => {
      setNavState({ ...navState, isAnimating: true })
      await controls.start("enterBottom")
      await controls.start("covering")
      await navigate(destination)
      setNavState({ ...navState, isAnimating: false })

      return await setTimeout(() => {
        return controls.start("exitTop")
      }, 150)
    }
    setTransitionPageTo(() => pageTransition)

    setTimeout(() => {
      controls.start("exitTop")
    }, 300)
  }, [])

  return (
    <motion.div
      className="page-transition-panels__container"
      variants={CONTAINER_VARIANTS}
      animate={controls}
    >
      <motion.div
        variants={PANEL_VARIANTS}
        initial={{ y: 0 }}
        custom={0}
        className="page-transition-panels__panel"
      ></motion.div>
      <motion.div
        variants={PANEL_VARIANTS}
        initial={{ y: 0 }}
        custom={1}
        className="page-transition-panels__panel"
      ></motion.div>
      <motion.div
        variants={PANEL_VARIANTS}
        initial={{ y: 0 }}
        custom={2}
        className="page-transition-panels__panel"
      ></motion.div>
    </motion.div>
  )
}

export default PageTransitionPanels
