import React from "react"
import { Link } from "gatsby"

import "./Footer.scss"
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai"
import PageTransitionLink from "../components/PageTransitionLink"

const footerPageLinkItems = [
  {
    title: "Architectural Rendering",
    to: "/architectural-rendering/",
  },
  {
    title: "Virtual Reality",
    to: "/virtual-reality/",
  },
  {
    title: "Portfolio",
    to: "/portfolio/",
  },
  {
    title: "Pricing",
    to: "/pricing/",
  },
  {
    title: "Contact",
    to: "/contact/",
  },
]

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__page-links">
          <h4 className="footer__maxvr-logo font-extrabold">
            <PageTransitionLink to="/">
              <span className="font-light">MAX</span>VR
            </PageTransitionLink>
          </h4>
          {footerPageLinkItems.map(pageLink => (
            <PageTransitionLink
              to={pageLink.to}
              activeStyle={{ fontWeight: "bold" }}
              key={pageLink.title}
            >
              {pageLink.title}
            </PageTransitionLink>
          ))}
        </div>
        <div className="footer__socials">
          <a
            href="https://www.youtube.com/channel/UCD8R-04ilqMuyw-KHmGDbgQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillYoutube />
          </a>
          <a
            href="https://www.instagram.com/maxvirtualreality/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillInstagram />
          </a>
          <a
            href="https://www.facebook.com/MaxVirtualReality"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillFacebook />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
