// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-architectural-rendering-js": () => import("./../../../src/pages/architectural-rendering.js" /* webpackChunkName: "component---src-pages-architectural-rendering-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-virtual-reality-js": () => import("./../../../src/pages/virtual-reality.js" /* webpackChunkName: "component---src-pages-virtual-reality-js" */),
  "component---src-templates-panoramic-image-js": () => import("./../../../src/templates/panoramic-image.js" /* webpackChunkName: "component---src-templates-panoramic-image-js" */)
}

