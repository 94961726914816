import React from "react"
import { Link } from "gatsby"
import { useContext } from "react"
import { context } from "../../provider"
import { useLocation } from "@reach/router"

const PageTransitionLink = props => {
  const { to, children, clickFunction } = props
  const { transitionPageTo, navState, setNavState } = useContext(context)
  const location = useLocation()

  const handleClick = e => {
    e.preventDefault()
    if (location.pathname === to) return
    if (location.pathname === to.split("#")[0]) return
    if (navState.isAnimating === true) return
    setNavState({ ...navState, isOpen: false })
    transitionPageTo(to)
  }

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  )
}

export default PageTransitionLink
