/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/tailwind.scss"

import "./src/global.scss"

import Provider from "./provider"

export const wrapRootElement = Provider

export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state === null) return
  const { disableScrollUpdate } = routerProps.location.state
  return !disableScrollUpdate
}
