import { useEffect, useState } from "react"
import { useWindowSize } from "react-use"

const mediaQueries = {
  desktop: 2520,
  smdesktop: 1919,
  laptop: 1599,
  smlaptop: 1365,
  tablet: 1023,
  smtablet: 767,
  mobile: 479,
}
export const returnContentfulMediaType = media => {
  return media.file.contentType.split("/")[0]
}

export const useReturnMediaSize = () => {
  const { width } = useWindowSize()
  if (width < mediaQueries.mobile) return "mobile"
  if (width < mediaQueries.smtablet) return "smtablet"
  if (width < mediaQueries.tablet) return "tablet"
  if (width < mediaQueries.smlaptop) return "smlaptop"
  if (width < mediaQueries.laptop) return "laptop"
  if (width < mediaQueries.smdesktop) return "smdesktop"
  if (width < mediaQueries.desktop) return "desktop"
  return "lgdesktop"
}
export const useSupportsWebP = () => {
  const [supportsWebP, setSupportsWebP] = useState(null)
  useEffect(() => {
    const elem = document.createElement("canvas")
    if (!!(elem.getContext && elem.getContext("2d"))) {
      const webPSupported =
        elem.toDataURL("image/webp").indexOf("data:image/webp") == 0

      setSupportsWebP(webPSupported)

      console.log({ webPSupported })
    }
    // very old browser like IE 8, canvas not supported
  }, [])
  return supportsWebP
}
export const modulo = (n, m) => {
  return ((n % m) + m) % m
}

export const useMatchMedia = size => {
  const [isMatching, setIsMatching] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (!mediaQueries[size]) return false
    setIsMatching(mediaQueries[size] > window.innerWidth)
  }, [width, size])

  return isMatching
}

export const select = query => {
  return document.querySelector(query)
}
export const selectAll = query => {
  return document.querySelectorAll(query)
}

export const useLogStateOnUpdate = (label, state) => {
  useEffect(() => {
    console.log(label, state)
  }, [state, label])
}
